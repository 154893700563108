// /* You can add global styles to this file, and also import other style files */
@import "~ng-zorro-antd/ng-zorro-antd.min.css";
// body{
//     -moz-transform: scale(0.8,0.8);
//     zoom:100%;
//     zoom:0.8;
// }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.txt-left {
  text-align: left !important;
  margin-left: 20%;
}

@media screen and (max-width: 768px) {
  .txt-left {
      margin-left: 0; /* Reset margin for small screens */
  }
}


.ant-menu-sub.ant-menu-inline {

  // background: #ff8d02;
  border-radius: 0;

}
body {
  -moz-transform: scale(0.9, 0.9);
  zoom: 0.91;
  // zoom: 80%;
}


